<template>
  <swiper :options="swiperOption" ref="mySwiper">
    <swiper-slide>
      <img src="@/assets/imgs/BG1.png" alt="" class="last-img">
    </swiper-slide>
    <swiper-slide>
      <img src="@/assets/imgs/BG2.png" alt="" class="last-img">
    </swiper-slide>
    <swiper-slide>
      <img src="@/assets/imgs/BG3.png" alt="" class="last-img">
    </swiper-slide>
    <swiper-slide>
      <img src="@/assets/imgs/BG4.png" alt="" class="last-img">
    </swiper-slide>
    <swiper-slide>
      <img src="@/assets/imgs/BG5.png" alt="" class="last-img">
    </swiper-slide>
    <!-- Optional controls -->
    <div class="swiper-pagination"  slot="pagination"></div>
  </swiper>
</template>

<script>
  import 'swiper/dist/css/swiper.css'

  import { swiper, swiperSlide } from 'vue-awesome-swiper'

  export default {
    name: 'index',
    components: {
      swiper,
      swiperSlide
    },
    data () {
      return {
        swiperOption: {
          // width: window.innerWidth,
          // height: window.innerHeight,
          slideActiveClass: 'index-slide-active',
          cancelable: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      }
    },
    computed: {
      swiper () {
        return this.$refs.mySwiper.swiper
      }
    },
    mounted () {
    }
  }
  document.title = '广州友爱网络科技有限公司'
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .swiper-container {
    position: absolute;
    width: 100%;
    height: calc(100%);
    z-index: 1;
    background-color: #f5f5f5;
  }
  .figure-left {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .figure-right {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
  .first-slide-text {
    position: absolute;
    top: 12%;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    transform: translateY(-20px);
    opacity: 0;
    transition: all .8s linear;
  }
  .index-slide-active .first-slide-text {
    transform: translateY(0);
    opacity: 1;
  }
  .first-slide-figure {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    width: 90%;
    transform: translateY(20px);
    transition: all .8s linear;
  }
  .index-slide-active .first-slide-figure {
    transform: translateY(0);
  }
  .slide-text {
    position: absolute;
    top: 3%;
    width: 100%;
  }
  .slide-figure {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(20px);
    transition: all .8s linear;
  }
  .index-slide-active .slide-figure {
    transform: translateY(0);
  }
  .last-img {
    position: absolute;
    /*top: 10%;*/
    width: 100%;
    height: 100%;
    transform: translateY(20px);
    transition: all .8s linear;
  }
  .index-slide-active .last-img {
    transform: translateY(0);
  }
</style>
<style>
  .swiper-container .swiper-pagination {
    bottom: 180px!important;
  }
  .swiper-container .swiper-pagination-bullet {
    margin: 0 10px!important;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    outline: none;
  }
  .swiper-container .swiper-pagination-bullet-active {
    background-color: #fff;
  }
</style>
