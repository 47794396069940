<template>
  <div id="app">
    <!--<the-header></the-header>-->
    <keep-alive><router-view/></keep-alive>
    <the-footer></the-footer>
  </div>
</template>

<script>
  // import theHeader from '@/components/common/theHeader'
  import theFooter from '@/components/theFooter'
  export default {
    name: 'App',
    components: {
      theFooter
    }
  }
</script>

<style>
  body, div, h1, h2, h3, h4, h5, h6, hr, p, a, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    height: 100%;
  }
  body {
    height: 100%;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: 100;
  }
  ul, ol, li {
    list-style: none;
  }
  em, i {
    font-style: normal;
  }
  img {
    border: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover, a:focus {
    text-decoration: none;
  }
  #app {
    /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
    font-family: -apple-system,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
