<template>
  <div class="the-footer">
    <!--<a :href="download_url" target="_blank">-->
    <a target="_blank">
      <img src="@/assets/imgs/downloadButton.png" alt="">
      <p>立即下载随时聊APP</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'theFooter',
  data () {
    return {
      // 苹果下载链接：https://www.pgyer.com/o172
      // 安卓下载链接：http://a.app.qq.com/o/simple.jsp?pkgname=com.yuanye.rentfriends
      download_url: ''
    }
  },
  mounted () {
    // console.log(navigator.userAgent)
    this.downloadUrl()
  },
  methods: {
    downloadUrl () {
      var u = navigator.userAgent
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
      if (isAndroid) {
        // this.download_url = 'http://a.app.qq.com/o/simple.jsp?pkgname=com.yuanye.rentfriends'
        // this.download_url = 'https://www.pgyer.com/pwKw'
      }
      if (isIOS) {
        // this.download_url = 'https://www.pgyer.com/o172'
        // this.download_url = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.yuanye.rentfriends'
        // this.download_url = 'https://itunes.apple.com/cn/app/id1369737522?mt=8'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 135px;
  z-index: 100;
  background-color: rgba(0,0,0,.5);
}
img {
  height: 60px;
  margin-right: 20px;
}
p {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}
</style>
